.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-footer a {
  color: white;
  text-decoration: none;
}
.App-footer p {
  margin: 1px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card-container {
  border: 1px solid #dcdcdc; /* Light gray border */
  border-radius: 8px; /* Slightly curved corners */
  box-sizing: border-box; /* Include padding and border in width and height */
  padding: 20px;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-color: #4b55b3;
  padding: 20px;
}

.lower-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
